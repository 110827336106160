<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form
      ref="form"
      class="multi-col-validation"
    >
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="userDataComputed.note"
              outlined
              rows="3"
              :label="t('users.bio')"
              :readonly="option===2"
            ></v-textarea>
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-menu
              v-model="modal"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :open-on-click="option!==2"
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="inputDate"
                  ref="inputDate"
                  v-model="computedDateFormatted"
                  :label="t('users.birthday')"
                  color="primary"
                  outlined
                  clearable
                  dense
                  persistent-hint
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="() => {
                    date = new Date().toISOString().substr(0, 10)
                    $refs.inputDate.blur()
                  }"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                no-title
                color="primary"
                :max="nowDate"
                @input="modal = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <div class="d-flex justify-space-between align-items-start">
              <v-select
                v-model="phoneCode"
                dense
                outlined
                :items="phoneCodeOptions"
                item-text="text"
                item-value="value"
                style="
                  width: 35%;
                "
                :append-icon="option===2 ? null : icons.mdiMenuDown"
                :readonly="option===2"
                hide-details
                :rules="[required]"
                @change="() => phoneNumber = null"
              >
                <template v-slot:item="{ item }">
                  <img
                    :src="item.image"
                    :alt="item.value"
                    width="20"
                    height="auto"
                    class="mr-2"
                  >
                  {{ item.text }}
                </template>
              </v-select>

              <v-text-field
                v-model="phoneNumber"
                :label="t('users.phone')"
                dense
                outlined
                class="pl-2"
                :disabled="!phoneCode"
                :readonly="option===2"
                :rules="[required, validatePhone]"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <p class="text--primary mt-n3 mb-2">
              {{ t('users.gender') }}
            </p>
            <v-radio-group
              v-model="userDataComputed.gender"
              row
              class="mt-0"
              :readonly="option===2"
            >
              <v-radio
                value="M"
                :label="t('users.male')"
              >
              </v-radio>
              <v-radio
                value="F"
                :label="t('users.female')"
              >
              </v-radio>
              <v-radio
                value="O"
                :label="t('users.other')"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- action buttons -->
      <options-buttom-tab
        :tab-number="tab"
        :is-mode-save-edit="option !== 2"
        :info-required="true"
        :no-cancel="option === 3"
        :no-arrow="option !== 1"
        :no-save="option !== 3"
        @onSave="onSaveAction"
        @onCancel="onCancelAction"
        @onNextTab="changeTab"
      >
      </options-buttom-tab>
    </v-form>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { ref, computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { mdiCalendar } from '@mdi/js'
import { formatStdDate } from '@core/utils'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import usePhone from '@core/utils/usePhone'
import useCountry from '@core/utils/useCountry'
import { useUtils } from '@core/libs/i18n'
import parsePhoneNumber from 'libphonenumber-js'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const form = ref(null)
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions, initPhoneConfig } = usePhone()
    const { configObjFacility } = useSelectOptions()

    const nowDate = ref(new Date().toISOString().slice(0, 10))
    const date = props.dataParams.birthdate
      ? ref(new Date(props.dataParams.birthdate).toISOString().substr(0, 10))
      : ref(new Date().toISOString().substr(0, 10))
    const dateNow = new Date().toISOString().substr(0, 10)
    const menu = ref(false)
    const modal = ref(false)
    const computedDateFormatted = computed(() => (nowDate.value === date.value ? null : formatStdDate(date.value, 'datelist', configObjFacility.value.is12Hour, vm.$i18n.locale)))
    const userDataComputed = computed(() => props.dataParams)
    const initCode = ref('')
    const phoneCode = ref('')
    const phoneNumber = ref('')

    const onCancelAction = () => {
      form.value.reset()
      emit('cancelAction')
    }

    const changeTab = tab => {
      userDataComputed.value.birthdate = computedDateFormatted.value

      if (form.value.validate()) {
        emit('changeTab', tab, true, userDataComputed.value)
      }
    }

    const onSaveAction = () => {
      userDataComputed.value.birthdate = computedDateFormatted.value
      if (form.value.validate()) {
        emit('saveUser', userDataComputed.value)
      }
    }

    const validatePhone = e => {
      if (phoneCode.value && e) {
        const phoneNumberJS = parsePhoneNumber(e, phoneCode.value)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            userDataComputed.value.phone_number = phoneNumberJS.number

            return true
          }
        }
      }

      return 'Invalid number'
    }

    onMounted(async () => {
      if (props.option === 1) {
        const countryCode = getCountryCode()
        if (countryCode) {
          const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
          if (codeFinded) initCode.value = countryCode
          else initCode.value = 'US'
        }
        phoneCode.value = initCode.value
      }

      if (props.option !== 1) {
        const config = initPhoneConfig(props.dataParams.phone_number)
        phoneCode.value = config.code
        phoneNumber.value = config.phone_number
      }
    })

    return {
      form,
      date,
      dateNow,
      nowDate,
      menu,
      modal,
      phoneCode,
      phoneNumber,

      // computed
      userDataComputed,
      computedDateFormatted,
      phoneCodeOptions,
      configObjFacility,

      // Validations
      required,

      // Methods
      onCancelAction,
      changeTab,
      onSaveAction,
      validatePhone,

      // i18n
      t,

      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
